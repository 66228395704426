export const adminLteConf = {
    sidebarLeftMenu: [
        {
            label: 'Menu de Navegacion',
            separator: true
        },
        {
            label: ' Inicio', route: '/',
            iconClasses: 'fa fa-home ', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ]
        },
        { 
            label: ' Usuarios', 
            route: '/usuarios', 
            iconClasses: 'fa fa-user-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Usuarios App', 
            route: '/usuariosA', 
            iconClasses: 'fa fa-mobile', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Cots Activos', 
            route: '/cotsactivos', 
            iconClasses: 'fa fa-users', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Encuestas', 
            route: '/encuestas', 
            iconClasses: 'fa fa-list-ol', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Encuestas Diarias', 
            route: '/registros', 
            iconClasses: 'fa fa-list-ol', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Analítica Encuestas', 
            route: '/analiticaencuestas', 
            iconClasses: 'fa fa-pie-chart', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Encuestas', 
            route: '/cots', 
            iconClasses: 'fa fa-tasks', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: 'Tracking', 
            route: '/gps', 
            iconClasses: 'fa fa-map-marker', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Call Center', 
            route: '/callcenter', 
            iconClasses: 'fa fa-phone-square', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-red' 
                }
            ] 
        },
        { 
            label: ' Call Center', 
            route: '/distritalescc', 
            iconClasses: 'fa fa-phone-square', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-red' 
                }
            ] 
        },
        
    ]
};