// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environmnt.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url_api: 'http://192.168.100.48:3400/',
  url_api: 'https://backend.tlk.com.mx/',
  // url_api: 'https://api-quiz2021.herokuapp.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.